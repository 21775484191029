import { Box, Flex, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { animate, motion, useMotionValue } from 'framer-motion';
import React, { useCallback, useState } from 'react';

import useHasAccount from 'lib/hooks/useHasAccount';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';
import IconSvg from 'ui/shared/IconSvg';
import NavLink from 'ui/snippets/navigation/NavLink';

import NavLinkGroupMobile from './NavLinkGroupMobile';
import useColors from './useColors';
import { useRouter } from 'next/router';
import { route } from 'nextjs-routes';
import NavLinkMobile from './NavLinkMobile';
import IconPng from 'ui/shared/IconPng';
import config from 'configs/app';
import SettingsColorThemeMobile from '../topBar/settings/SettingsColorThemeMobile';

const DRAWER_WIDTH = 330;

interface Props {
  onNavLinkClick?: () => void;
  isMarketplaceAppPage?: boolean;
}

const NavigationMobile = ({ onNavLinkClick, isMarketplaceAppPage }: Props) => {
  const { mainNavItems, accountNavItems } = useNavItems();
console.log('====================================');
console.log(mainNavItems,'mainNavItems');
console.log('====================================');
  const [openedGroupIndex, setOpenedGroupIndex] = React.useState(-1);

  const mainX = useMotionValue(0);
  const subX = useMotionValue(DRAWER_WIDTH);

  const onGroupItemOpen = (index: number) => () => {
    setOpenedGroupIndex(index);
    animate(mainX, -DRAWER_WIDTH, { ease: 'easeInOut' });
    animate(subX, 0, { ease: 'easeInOut' });
  };

  const onGroupItemClose = useCallback(() => {
    animate(mainX, 0, { ease: 'easeInOut' });
    animate(subX, DRAWER_WIDTH, { ease: 'easeInOut', onComplete: () => setOpenedGroupIndex(-1) });
  }, [mainX, subX]);

  const hasAccount = useHasAccount();

  const iconColor = useColorModeValue('blue.600', 'blue.300');

  const openedItem = mainNavItems[openedGroupIndex];


  const isCollapsed = isMarketplaceAppPage ? false : undefined;
  const colors = useColors();
  const router = useRouter();
  const pathname = router.pathname;
  const result = useColorModeValue('light', 'dark');
  const Home = {
    "text": "Home",
    "nextRoute": {
        "pathname": "/"
    },
    "icon": "",
    "isActive": false
} as any;
  const Network = {
    "text": "Network",
    "icon": "restAPI",
    "isActive": false,
    "subItems": [

      {
        "text": "NetX Mainnet",
        "icon": "RPC",
        "url": "https://www.tscscan.io/"
      },
      {
        "text": "NetX Testnet",
        "icon": "RPC",
        "url": " https://testnet.tscscan.io/"
      }
    ]
  } as any;
  const ColorThem = {
    "text": "Theme",
    "icon": "restAPI",
    "isActive": false,
    "subItems": [

      {
        "text": "NetX Mainnet",
        "icon": "RPC",
        "url": "https://www.tscscan.io/"
      },
      {
        "text": "NetX Testnet",
        "icon": "RPC",
        "url": " https://testnet.tscscan.io/"
      }
    ]
  } as any;
  return (
    <Flex position="relative" flexDirection="column" flexGrow={1}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        as={motion.div}
        style={{ x: mainX }}
        maxHeight={openedGroupIndex > -1 ? '100vh' : 'unset'}
        overflowY={openedGroupIndex > -1 ? 'hidden' : 'unset'}
      >
        <Box
          as="nav"
          mt={6}
        >
          <VStack
            w="100%"
            as="ul"
            spacing="1"
            alignItems="flex-start"
          >

        
            <NavLinkMobile key={'Home'} item={Home} onClick={onNavLinkClick} isCollapsed={isCollapsed} notIcon={true} />

            {mainNavItems.map((item, index) => {
              if (isGroupItem(item)) {
                return <NavLinkGroupMobile key={item.text} item={item} onClick={onGroupItemOpen(index)} isExpanded={isMarketplaceAppPage} />;
              } else {
                return <NavLinkMobile key={item.text} item={item} onClick={onNavLinkClick} isCollapsed={isCollapsed} notIcon={true} />;
              }
            })}
            {/* chain */}
            <NavLinkGroupMobile key={'Network'} item={Network} onClick={onGroupItemOpen(10)} isExpanded={isMarketplaceAppPage} />
            {/* color */}
            <NavLinkGroupMobile key={'ColorThem'} item={ColorThem} onClick={onGroupItemOpen(15)} isExpanded={isMarketplaceAppPage} />

          </VStack>
        </Box>
        {hasAccount && (
          <Box
            as="nav"
            mt={3}
            pt={3}
            borderTopWidth="1px"
            borderColor="divider"
          >
            <VStack as="ul" spacing="1" alignItems="flex-start">
              {accountNavItems.map((item) => <NavLink key={item.text} item={item} onClick={onNavLinkClick} isCollapsed={isCollapsed} />)}
            </VStack>
          </Box>
        )}
      </Box>
      {openedGroupIndex >= 0 &&

        (
          openedGroupIndex === 10 ? <Box
            as={motion.nav}
            w="100%"
            mt={6}
            position="absolute"
            top={0}
            style={{ x: subX }}
            key="sub"
          >
            <Flex alignItems="center" px={3} py={2.5} w="100%" h="50px" onClick={onGroupItemClose} mb={1}>
              <IconSvg name="arrows/east-mini" boxSize={6} mr={2} color={iconColor} />
              <Text variant="secondary" fontSize="sm">{Network.text}</Text>
            </Flex>
            <Box
              w="100%"
              as="ul"
            >
              <Flex padding="8px 12px" style={{ cursor: 'pointer', borderRadius: '8px', alignItems: 'center' }} _hover={
                {
                  background: useColorModeValue(' #EAF3FF', '#1A202C'),
                  '& *': {
                    color: 'link_hovered',
                  },
                }

              }
                onClick={() => {
                  if (config.chain.isTestnet) {
                    window.open('https://www.tscscan.io/')
                  }
                }}
              >
                <IconPng name={`next_main_li_${result}`} width="30px" height="30px" marginRight="12px" />
                <Text>NetX Mainnet</Text>
              </Flex>
              <Flex padding="8px 12px" style={{ cursor: 'pointer', borderRadius: '8px', alignItems: 'center' }} _hover={
                {
                  background: useColorModeValue(' #EAF3FF', '#1A202C'),
                  '& *': {
                    color: 'link_hovered',
                  },
                }
              }
                onClick={() => {
                  if (!config.chain.isTestnet) {
                    window.open('https://testnet.tscscan.io/')
                  }
                }}

              >
                <IconPng name={`next_test_li_${result}`} width="30px" height="30px" marginRight="12px" />
                <Text>NetX Testnet</Text>
              </Flex>
            </Box>
          </Box>
            :
            openedGroupIndex === 15 ? <Box
              as={motion.nav}
              w="100%"
              mt={6}
              position="absolute"
              top={0}
              style={{ x: subX }}
              key="sub"
            >
              <Flex alignItems="center" px={3} py={2.5} w="100%" h="50px" onClick={onGroupItemClose} mb={1}>
                <IconSvg name="arrows/east-mini" boxSize={6} mr={2} color={iconColor} />
                <Text variant="secondary" fontSize="sm">{ColorThem.text}</Text>
              </Flex>
              <Box
                w="100%"
                as="ul"
              >
                <SettingsColorThemeMobile></SettingsColorThemeMobile>
              </Box>
            </Box>
              :


              <Box
                as={motion.nav}
                w="100%"
                mt={6}
                position="absolute"
                top={0}
                style={{ x: subX }}
                key="sub"
              >
                <Flex alignItems="center" px={3} py={2.5} w="100%" h="50px" onClick={onGroupItemClose} mb={1}>
                  <IconSvg name="arrows/east-mini" boxSize={6} mr={2} color={iconColor} />
                  <Text variant="secondary" fontSize="sm">{mainNavItems[openedGroupIndex].text}</Text>
                </Flex>
                <Box
                  w="100%"
                  as="ul"
                >
                  {isGroupItem(openedItem) && openedItem.subItems?.map(
                    (item, index) => Array.isArray(item) ? (
                      <Box
                        key={index}
                        w="100%"
                        as="ul"
                        _notLast={{
                          mb: 2,
                          pb: 2,
                          borderBottomWidth: '1px',
                          borderColor: 'divider',
                        }}
                      >
                        {item.map(subItem => <NavLink key={subItem.text} item={subItem} onClick={onNavLinkClick} isCollapsed={isCollapsed} />)}
                      </Box>
                    ) :
                      <NavLink key={item.text} item={item} mb={1} onClick={onNavLinkClick} isCollapsed={isCollapsed} />,
                  )}
                </Box>
              </Box>
        )}
    </Flex>
  );
};

export default NavigationMobile;
