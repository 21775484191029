import type {GridProps} from '@chakra-ui/react';
import {
  Box,
  Grid,
  Flex,
  Text,
  Link,
  VStack,
  Skeleton,
  Image,
  useColorModeValue,
  Center,
  HStack
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import React from 'react';

import type {CustomLinksGroup} from 'types/footerLinks';

import config from 'configs/app';
import type {ResourceError} from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import AddressEntity from '../../shared/entities/address/AddressEntity';
import IconSvg from '../../shared/IconSvg';
import TextSeparator from '../../shared/TextSeparator';
import FooterLinkItem from './FooterLinkItem';
import getApiVersionUrl from './utils/getApiVersionUrl';
import useIsMobile from "../../../lib/hooks/useIsMobile";
import {getEnvValue} from "../../../configs/app/utils";
import useNetworkMenu from "../networkMenu/useNetworkMenu";

const MAX_LINKS_COLUMNS = 4;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${config.UI.footer.frontendVersion}`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${config.UI.footer.frontendCommit}`;

const Footer = () => {
  const handleNetworkLogoClick = () => {
    window.location.reload();
  };
  const {data: backendVersionData} = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  const BLOCKSCOUT_LINKS = [
    {
      icon: 'media/twitter' as const,
      iconSize: '20px',
      text: '',
      url: 'https://x.com/netx_world',
    },
    // {
    //   icon: 'media/discord' as const,
    //   iconSize: '20px',
    //   text: '',
    //   url: 'https://discord.com/invite/npHRqa7uph',
    // },
    {
      icon: 'media/medium' as const,
      iconSize: '20px',
      text: '',
      url: 'https://medium.com/@netxworld',
    },
    {
      icon: 'media/telegram' as const,
      iconSize: '20px',
      text: '',
      url: 'https://t.me/netx_world',
    },
    {
      icon: 'media/gitbook' as const,
      iconSize: '20px',
      text: '',
      url: 'https://netx.gitbook.io/netx-docs',
    },
    {
      icon: 'media/github' as const,
      iconSize: '20px',
      text: '',
      url: 'hhttps://github.com/netxnetwork',
    },
    {
      icon: 'media/mail' as const,
      iconSize: '20px',
      text: '',
      url: 'mailto:contact@netx.world',
    },
   
  ];

  const frontendLink = (() => {
    if (config.UI.footer.frontendVersion) {
      return <Link href={FRONT_VERSION_URL} target="_blank">{config.UI.footer.frontendVersion}</Link>;
    }

    if (config.UI.footer.frontendCommit) {
      return <Link href={FRONT_COMMIT_URL} target="_blank">{config.UI.footer.frontendCommit}</Link>;
    }

    return null;
  })();

  const fetch = useFetch();

  const {isPlaceholderData, data: linksData} = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
    queryKey: ['footer-links'],
    queryFn: async () => fetch(config.UI.footer.links || '', undefined, {resource: 'footer-links'}),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
    placeholderData: [],
  });

  const colNum = isPlaceholderData ? 1 : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;
  const isLight = useColorModeValue('light', 'dark');
  // eslint-disable-next-line
  const renderNetworkInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
    return (
      <Flex
        w="100%"
        justifyContent="space-between"
      >
        <Image
          w="84px"
          h="32px"
          src={`/static/logo-${isLight}.png`}
          alt="logo icon"
          cursor="pointer"
          onClick={handleNetworkLogoClick}
        />
        { /*{!config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/>}*/}
        <NetworkAddToWallet/>
      </Flex>
    );
  }, [isLight]);


  const containerProps: GridProps = {
    as: 'footer',
    px: {base: 4, lg: 12},
    pb: {base: 4, lg: 8},

    gridTemplateColumns: {base: '1fr', lg: 'minmax(auto, 470px) 1fr'},
    columnGap: {lg: '32px', xl: '100px'},
  };

  const bgColor = useColorModeValue('gray.100', '#000000');
  const textColor = useColorModeValue('black', 'white');
  if (config.UI.footer.links) {
    return (
      <Grid {...containerProps}>
        <div>
          { /*{renderNetworkInfo()}*/}
          {/*{renderProjectInfo()}*/}
        </div>

        <Grid
          gap={{base: 6, lg: colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8, xl: 12}}
          gridTemplateColumns={{
            base: 'repeat(auto-fill, 160px)',
            lg: `repeat(${colNum}, 135px)`,
            xl: `repeat(${colNum}, 160px)`,
          }}
          justifyContent={{lg: 'flex-end'}}
          mt={{base: 8, lg: 0}}
        >
          {
            ([
              {title: 'Blockscout', links: BLOCKSCOUT_LINKS},
              ...(linksData || []),
            ])
              .slice(0, colNum)
              .map(linkGroup => (
                <Box key={linkGroup.title}>
                  <Skeleton fontWeight={500} mb={3} display="inline-block"
                            isLoaded={!isPlaceholderData}>{linkGroup.title}</Skeleton>
                  <VStack spacing={1} alignItems="start">
                    {linkGroup.links.map(link => (
                      <FooterLinkItem {...link} key={link.text}
                                      isLoading={isPlaceholderData}/>
                    ))}
                  </VStack>
                </Box>
              ))
          }
        </Grid>
      </Grid>
    );
  }
  const isMobile = useIsMobile()
  const NetStr = getEnvValue('NEXT_PUBLIC_NETWORK_NAME');
  const NetStrlast =NetStr?.toString().split(' ').join('-');

  return (
    <>
      <Box px={{base: 4, lg: 12}} py={{base: 4, lg: 8}} borderTop="0px solid" borderColor="divider">
        <Box w="100%">
          {renderNetworkInfo({lg: 'network'})}
        </Box>
        <Box w="100%" display="inline-flex" justifyContent={isMobile ? "flex-start" : "space-between"} flexWrap={"wrap"}
             pt={6}>
          <Box color="text_secondary" fontSize={isMobile ? "xs" : "xs"}>
            TSCSCAN is a Block Explorer and Analytics Platform for NetX.
          </Box>

          <Box display="inline-flex" py={isMobile ? 4 : 0} width={isMobile ? "100%" : "fit-content"}
               justifyContent={isMobile ? "flex-start" : "flex-end"}>
            {BLOCKSCOUT_LINKS.map((link, index) => <FooterLinkItem {...link}
                                                                   key={link.text + index}/>)}
          </Box>
        </Box>
      </Box>
      <Box display="flex" w="100%" h="fit-content" alignItems="center" flexWrap={'wrap'}
           justifyContent={isMobile ? "flex-start" : "space-between"}
           px={isMobile?4: 12} py={{base: 6, lg: 6}} backgroundColor={bgColor}>
        <Box fontSize="xs" color={textColor}>TscScan © 2024 ({NetStrlast})</Box>
        <Box fontSize="xs" display="inline-flex" alignItems="center" flexWrap={'wrap'}>
          <Link w={isMobile ? '100%' : 'fit-content'} color="text_secondary"
                href="https://docs.trias.one/triasmainnet/terms-of-service" display="flex"
                alignItems="center" h="30px" variant="secondary" target="_blank" fontSize="xs">
            Terms of Service
          </Link>
          {isMobile ? '' : <TextSeparator color="gray.500"/>}
          <Link w={isMobile ? '100%' : 'fit-content'} color="text_secondary"
                href="https://docs.trias.one/triasmainnet/privacy-policy" display="flex"
                alignItems="center" h="30px" variant="secondary" target="_blank" fontSize="xs">
            Privacy Policy
          </Link>
          {isMobile ? '' : <TextSeparator color="gray.500"/>}
          {isMobile ? <HStack w={'100%'} justifyContent={'flex-start'}>
              <Text w={'fit-content'} textTransform="capitalize" color="text_secondary">Donations</Text>
              <AddressEntity
                address={{hash: '0x1cD7a18909C948af8C56a991e9aB407F5942A442'}}
                noIcon
                noCopy
                truncation="constant"
                ml="2"
              />
              <IconSvg ml={2} mt={'-2px'} name="heart" boxSize={3}/>
            </HStack> :
            <Box display={"inline-flex"} alignItems={"center"}>
              <Text w={'fit-content'} textTransform="capitalize" color="text_secondary">Donations</Text>
              <AddressEntity
                address={{hash: '0x1cD7a18909C948af8C56a991e9aB407F5942A442'}}
                noIcon
                noCopy
                truncation="constant"
                ml="2"
              />
              <IconSvg ml={2} mt={'-2px'} name="heart" boxSize={3}/>
            </Box>
          }
        </Box>
      </Box>
    </>

  );
};

export default React.memo(Footer);
