import type { HTMLChakraProps } from '@chakra-ui/react';
import { Skeleton, Image } from '@chakra-ui/react';
import React from 'react';

interface Props extends HTMLChakraProps<'div'> {
  name: string;
  isLoading?: boolean;
}

const IconPng = ({ name, isLoading, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <Skeleton isLoaded={ !isLoading } display="inline-block" { ...props } ref={ ref }>
      <Image src={ `/static/home/${ name }.png` } alt={ name }/>
    </Skeleton>
  );
};

export default React.forwardRef(IconPng);
