import { Flex, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import IconPng from 'ui/shared/IconPng';
import config from 'configs/app';

const ChainBar = () => {
  const result = useColorModeValue('light', 'dark');

  return (
    <Popover 
    // trigger="hover"
    
    >
      <PopoverTrigger>
        <IconButton
          variant="simple"
          colorScheme="blue"
          aria-label="chain"
          icon={ <IconPng name={ `next_main_${ result }` } boxSize={ 5 } width="24px" height="24px"/> }
          margin="0 40px"
        />
      </PopoverTrigger>
      <PopoverContent overflowY="hidden" w="auto" fontSize="sm">
        <PopoverBody boxShadow="2xl"style={{ padding: '24px' }}>
          <Flex padding="8px 12px" style={{ cursor: 'pointer', borderRadius: '8px', alignItems: 'center' }} _hover={
            {
              background: useColorModeValue(' #EAF3FF', '#1A202C'),
              '& *': {
                color: 'link_hovered',
              },
            }
            
          }
          onClick={()=>{
            if(config.chain.isTestnet){
              window.open('https://www.tscscan.io/')
            }
          }}
          >
            <IconPng name={ `next_main_li_${ result }` } width="30px" height="30px" marginRight="12px"/>
            <Text>NetX Mainnet</Text>
          </Flex>
          <Flex padding="8px 12px" style={{ cursor: 'pointer', borderRadius: '8px', alignItems: 'center' }} _hover={
            {
              background: useColorModeValue(' #EAF3FF', '#1A202C'),
              '& *': {
                color: 'link_hovered',
              },
            }
          }
           onClick={()=>{
            if(!config.chain.isTestnet){
              window.open('https://testnet.tscscan.io/')
            }
           }}
          
          >
            <IconPng name={ `next_test_li_${ result }` } width="30px" height="30px" marginRight="12px"/>
            <Text>NetX Testnet</Text>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(ChainBar);
