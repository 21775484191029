import { Box, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import IconPng from 'ui/shared/IconPng';

import SettingsColorTheme from './SettingsColorTheme';
import SettingsIdentIcon from './SettingsIdentIcon';

const Settings = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const result = useColorModeValue('light', 'dark');
  return (
    <Popover placement="bottom-start" trigger="click" isOpen={ isOpen } onClose={ onClose }>
      <PopoverTrigger>
        <IconButton
          variant="simple"
          colorScheme="blue"
          aria-label="User settings"
          icon={ <IconPng name={ `setting_${ result }` } width="24px" height="24px"/> }
          onClick={ onToggle }
        />
      </PopoverTrigger>
      <PopoverContent overflowY="hidden" w="auto" fontSize="sm">
        <PopoverBody boxShadow="2xl" p={ 4 }>
          <SettingsColorTheme/>
          {/* <Box borderColor="divider" borderWidth="1px" my={ 3 }/> */}
          {/* <SettingsIdentIcon/> */}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(Settings);
