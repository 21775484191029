import { Box, Center, Link, Skeleton, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';

import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';
import useIsMobile from '../../../lib/hooks/useIsMobile';

type Props = {
  icon?: IconName;
  iconSize?: string;
  text: string;
  url: string;
  isLoading?: boolean;
}

const FooterLinkItem = ({ icon, iconSize, text, url, isLoading }: Props) => {
  const IconColor = useColorModeValue('black', 'white');
  if (isLoading) {
    return <Skeleton my="3px">{ text }</Skeleton>;
  }
  const isMobile = useIsMobile();
  const [ isMobileOpen, setIsMobileOpen ] = useState(false);
  let timer :any;
  if (icon === 'media/discord') {
    return (
      <Tooltip label="Coming Soon" isOpen={ isMobileOpen }>
        <Center minW={ 6 } ml={ isMobile ? 0 : 2 } mr={ isMobile ? 4 : 0 } onClick={ () => {
          clearTimeout(timer);
          setIsMobileOpen(true);
          timer = setTimeout(() => {
            setIsMobileOpen(false);
          }, 2000);
        } } onMouseEnter={ () => {
          setIsMobileOpen(true);
        } } onMouseLeave={ () => {
          setIsMobileOpen(false);
        } }>
          <IconSvg boxSize={ isMobile ? 8 : 5 } name={ icon } color={ IconColor }/>
        </Center>
      </Tooltip>
    );
  } else {
    return (
      <Link href={ url } display="flex" alignItems="center" h="auto" variant="secondary" target="_blank">
        { icon && (
          <Center minW={ 6 } ml={ isMobile ? 0 : 2 } mr={ isMobile ? 4 : 0 }>
            <IconSvg boxSize={ isMobile ? 8 : 5 } name={ icon } color={ IconColor }/>
          </Center>
        ) }
        { text }
      </Link>
    );
  }

};

export default FooterLinkItem;
