import { Box, Flex, useColorMode, useColorModeValue, useToken } from '@chakra-ui/react';
import React from 'react';

import * as cookies from 'lib/cookies';
import { COLOR_THEMES } from 'lib/settings/colorTheme';

import SettingsSample from './SettingsSample';

const SettingsColorThemeMobile = () => {
    const { setColorMode } = useColorMode();

    const [activeHex, setActiveHex] = React.useState<string>();

    const setTheme = React.useCallback((hex: string) => {
        const nextTheme = COLOR_THEMES.find((theme) => theme.hex === hex);

        if (!nextTheme) {
            return;
        }

        setColorMode(nextTheme.colorMode);

        const varName = nextTheme.colorMode === 'light' ? '--chakra-colors-white' : '--chakra-colors-black';
        window.document.documentElement.style.setProperty(varName, hex);

        cookies.set(cookies.NAMES.COLOR_MODE_HEX, hex);
        window.localStorage.setItem(cookies.NAMES.COLOR_MODE, nextTheme.colorMode);
    }, [setColorMode]);

    React.useEffect(() => {
        const cookieColorMode = cookies.get(cookies.NAMES.COLOR_MODE);

        const nextColorMode = (() => {
            if (!cookieColorMode) {
                return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            }

            return cookieColorMode;
        })();

        const colorModeThemes = COLOR_THEMES.filter(theme => theme.colorMode === nextColorMode);
        const fallbackHex = colorModeThemes[colorModeThemes.length - 1].hex;
        const cookieHex = cookies.get(cookies.NAMES.COLOR_MODE_HEX) ?? fallbackHex;
        setTheme(cookieHex);
        setActiveHex(cookieHex);
        // should run only on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelect = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        const hex = event.currentTarget.getAttribute('data-value');

        if (!hex) {
            return;
        }

        setTheme(hex);
        setActiveHex(hex);
    }, [setTheme]);

    const activeTheme = COLOR_THEMES.find((theme) => theme.hex === activeHex);
    const bgColor = useColorModeValue('white', 'gray.900');
    const activeBgColor = useColorModeValue('blue.50', 'whiteAlpha.100');
  
    const activeBorderColor = useToken('colors', useColorModeValue('blackAlpha.800', 'gray.50'));
    const hoverBorderColor = useToken('colors', 'link_hovered');
    return (
        <>
            {/* <Box color="text_secondary" mt={1} mb={2}>{activeTheme?.label}</Box> */}
            {/* <Flex>
                {COLOR_THEMES.map((theme) => (
                    <SettingsSample
                        key={theme.label}
                        label={theme.label}
                        value={theme.hex}
                        bg={theme.sampleBg}
                        isActive={theme.hex === activeHex}
                        onClick={handleSelect}
                    />
                ))}
            </Flex> */}

            {
                COLOR_THEMES.map((theme) => {
                    return <Flex  alignItems={'center'}  onClick={handleSelect}  data-value={theme.hex}>
                        <Box p="9px" bgColor={theme.hex === activeHex ? activeBgColor : 'transparent'} borderRadius="base" marginRight={'12px'}>
                            <Box
                                bg={theme.sampleBg}
                                boxSize="22px"
                                borderRadius="full"
                                borderWidth="1px"
                                borderColor={theme.hex === activeHex ? activeBgColor : bgColor}
                                position="relative"
                                cursor="pointer"
                                _before={{
                                    position: 'absolute',
                                    display: 'block',
                                    content: '""',
                                    top: '-3px',
                                    left: '-3px',
                                    width: 'calc(100% + 2px)',
                                    height: 'calc(100% + 2px)',
                                    borderStyle: 'solid',
                                    borderRadius: 'full',
                                    borderWidth: '2px',
                                    borderColor: theme.hex === activeHex ? activeBorderColor : 'transparent',
                                }}
                                _hover={{
                                    _before: {
                                        borderColor: theme.hex === activeHex ? activeBorderColor : hoverBorderColor,
                                    },
                                }}
                                // data-value={theme.hex}
                                // onClick={handleSelect}
                            />
                        </Box>
                        <Box as='span'>{theme.label}</Box>
                    </Flex>
                })
            }

        </>
    );
};

export default React.memo(SettingsColorThemeMobile);
