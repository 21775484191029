import { Link, Text, HStack, Tooltip, Box, useBreakpointValue, chakra, shouldForwardProp, useColorModeValue } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';

import type { NavItem } from 'types/client/navigation-items';

import { route } from 'nextjs-routes';

import useIsMobile from 'lib/hooks/useIsMobile';
import { isInternalItem } from 'lib/hooks/useNavItems';
import IconSvg from 'ui/shared/IconSvg';

import NavLinkIcon from './NavLinkIcon';
import useColors from './useColors';
import useNavLinkStyleProps from './useNavLinkStyleProps';
import { useRouter } from 'next/router';

type Props = {
  item: NavItem;
  isCollapsed?: boolean;
  px?: string | number;
  className?: string;
  onClick?: () => void;
  disableActiveState?: boolean;
  notIcon?: boolean;
}

const NavLinkMobile = ({ item, isCollapsed, className, onClick, disableActiveState, notIcon }: Props) => {
  const isMobile = useIsMobile();
  const colors = useColors();
  const router = useRouter();
  const pathname = router.pathname;
  const isExpanded = isCollapsed === false;
  const isInternalLink = isInternalItem(item);

  const styleProps = useNavLinkStyleProps({ isCollapsed, isExpanded, isActive: isInternalLink && item.isActive && !disableActiveState });
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const href = isInternalLink ? route(item.nextRoute) : item.url;
  const color = useColorModeValue('#EAF3FF ', '#1A202C');
  const content = (
    <Link
      href={ href }
      target={ isInternalLink ? '_self' : '_blank' }
      { ...styleProps.itemProps }
      display="flex"
    //   padding={ notIcon ? '0 0 0 40px' : '0' }
      aria-label={ `${ item.text } link` }
      whiteSpace="nowrap"
      onClick={ onClick }
    >
      <Tooltip
        label={ item.text }
        hasArrow={ false }
        isDisabled={ isMobile || isCollapsed === false || (isCollapsed === undefined && isXLScreen) }
        placement="right"
        variant="nav"
        gutter={ 20 }
        color={ isInternalLink && item.isActive ? colors.text.active : colors.text.hover }

      >
        <HStack overflow="hidden">
          {
            !notIcon && <NavLinkIcon item={ item }/>
          }

          <Text { ...styleProps.textProps } as="span">
          

            {
                (pathname === '/' && href === '/' )?   <span style={{ color: pathname === '/' && href === '/' ? colors.text.active : colors.text.default}}>{ item.text }</span>:  <span >{ item.text }</span>
            }

            
            { !isInternalLink && <IconSvg name="arrows/north-east" boxSize={ 4 } color="text_secondary" verticalAlign="middle"/> }
          </Text>
        </HStack>
      </Tooltip>
    </Link>
  );

  return (
    <Box as="li" listStyleType="none" className={ className } width={ notIcon ? '' : '100%' } _hover={{
      background: notIcon ? 'none' : color,

    }}
    style={{
    //   // color: notIcon ? '' : '#2C5282',
    //   padding: notIcon ? '0' : '8px 12px',
    //   borderRadius: notIcon ? '0' : '8px',
    //   height: notIcon ? 'auto':'46px',
    //   display: notIcon ? 'auto':'flex',
    //   alignItems: notIcon ? 'auto':'center',
    }}

    >
      { isInternalLink ? (
        <NextLink href={ item.nextRoute } passHref legacyBehavior >
          { content }
        </NextLink>
      ) : content }
    </Box>
  );
};

const NavLinkMobileChakra = chakra(NavLinkMobile, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);

    if (isChakraProp && prop !== 'px') {
      return false;
    }

    return true;
  },
});

export default NavLinkMobile
